import React from 'react'
import { graphql } from 'gatsby'
import posed from 'react-pose'
import { TransitionState } from 'gatsby-plugin-transition-link'
import styled from 'styled-components'
import Page from '../components/page'
import Post from '../components/post'
import Footer from '../components/footer'
import Date from '../components/date'
import BlogHeader from '../components/blogHeader'
import BlogTitle from '../components/blogTitle'
import BlogIntro from '../components/blogIntro'
import PreviousText from '../components/previousText'
import Article from '../components/article'
import { Helmet } from 'react-helmet'

const PageTransitionStates = posed.div({
  startState: {
    opacity: 1,
    y: 0,
  },
  exitedState: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
    },
  },
})

const OverlayTransitionStates = {
  startState: {
    opacity: 1,
    transition: {
      duration: 300,
    },
  },
  exitedState: {
    opacity: 1,
    delay: 300,
    applyAtStart: { display: 'block' },
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: {
      duration: 300,
    },
  },
}

const TransitionOverlay = styled(posed.div(OverlayTransitionStates))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #f6f6f6;
  opacity: 1;
`

const ContentPage = styled.div`
  margin: 0rem 0 0;
  padding: 0rem 0 0;
`

const Posts = styled.div`
  margin: 8rem auto 0rem;
  padding: 5rem 0 5rem;
  max-width: 1200px;
  @media (max-width: 1250px) {
    margin: 0rem 2rem 0rem;
  }
  @media (max-width: 768px) {
    margin: 0rem 2rem 0rem;
  }
`

const PostsContainer = styled.div`
  background: linear-gradient(90deg, rgb(246, 247, 248) 12px, transparent 1%)
      center,
    linear-gradient(rgb(246, 247, 248) 12px, transparent 1%) center, #d8d8d8;
  background-size: 15px 15px;
  width: 100vw;
`

const PostContainer = styled.div`
  margin: 8rem auto 3rem;
  max-width: 1200px;
  @media (max-width: 1250px) {
    margin: 3rem 2rem 3rem;
  }
  @media (max-width: 768px) {
    margin: 3rem 2rem 3rem;
  }
`

const ArticleHTML = styled.article``

function getTransitionStates(status) {
  if (['entering', 'entered'].includes(status)) {
    return 'visible'
  } else if (['exiting'].includes(status)) {
    return 'exitedState'
  } else if (['exited'].includes(status)) {
    return 'startState'
  } else if (['POP'].includes(status)) {
    return 'visible'
  }
}

const PageThree = props => (
  <ContentPage>
    <Page>
      <Helmet>
        <script
          id="twitter-wjs"
          type="text/javascript"
          async
          defer
          src="//platform.twitter.com/widgets.js"
        />
      </Helmet>
      <TransitionState>
        {({ transitionStatus: status }) => {
          let posts = props.data.allMarkdownRemark.edges
          let firstPost = posts[0].node

          return (
            <PageTransitionStates pose={getTransitionStates(status)}>
              <BlogHeader />
              <PostContainer>
                <BlogTitle>
                  <a href={firstPost.frontmatter.path}>
                    {firstPost.frontmatter.title}
                  </a>
                </BlogTitle>
                <Date>{firstPost.frontmatter.date}</Date>
                <BlogIntro>{firstPost.frontmatter.intro}</BlogIntro>
                <Article>
                  <ArticleHTML
                    dangerouslySetInnerHTML={{
                      __html: firstPost.html,
                    }}
                  />
                </Article>
              </PostContainer>
              <PostsContainer>
                <Posts>
                  <PreviousText>More reading</PreviousText>
                  {posts.slice(1, posts.length).map(({ node }) => {
                    return <Post key={node.id} node={node} />
                  })}
                </Posts>
              </PostsContainer>
              <TransitionOverlay pose={getTransitionStates(status)} />
            </PageTransitionStates>
          )
        }}
      </TransitionState>
    </Page>
    <Footer dark={true} />
  </ContentPage>
)

export default PageThree

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 160)
          frontmatter {
            date(formatString: "MMMM DD YYYY")
            title
            path
            intro
            heroImage
          }
        }
      }
    }
  }
`
